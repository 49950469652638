h1 {
  text-align: center;
  color: #000 
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-image: url("..//public/conti_logo_transparent.png");
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 50px;
  padding: 50px;
  border-block-color: #000000;
  border-radius: 20px 25%;
  background-color: #ffa600be ;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 1);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}

button {
  background-color: #000000; 
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  margin-bottom: 1rem;
}

button:hover {
  background-color: #1f1f1f; 
}

.logo {
  width: auto;
  height: auto;
}

.contilogo {
  object-fit: contain;
  align-content: center;
  }

#start-btn,
#abort-btn {
  display: block;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

input[type="text"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 75%;
  max-width: 300px;
  margin-bottom: 1rem;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

img {
  max-width: 15%;
  }


.timer-container {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #000; /* Farbe nach Wahl */
}

